<template>
  <div>
    <div class="qchat-conversation">
      <Conversations />
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Conversations from "./Conversations.vue";
import Footer from "./Footer.vue";

export default {
  components: { Conversations, Footer },
  data() {
    return {
      message: {
        type: "text",
        content: ""
      }
    };
  },
  computed: {
    ...mapState("messenger", ["user", "styles"])
  }
};
</script>

<template>
  <div class="qchat-conversation-footer">
    <VEmojiPicker
      v-if="display.emoji"
      label-search="Search"
      lang="pt-BR"
      @select="onSelectEmoji"
    />
    <div class="qcentered-item">
      <div class="qchat-conversation-footer__kit">
        <input
          type="text"
          name="message"
          id="message"
          :value="message.content"
          class="qchat-conversation-footer__kit__input"
          placeholder="Send a message…"
          @keydown.enter.exact="sendMessage"
          @input="updateInputText($event)"
        />
        <div class="qchat-conversation-footer__kit__options">
          <input
            id="fileAttachment"
            ref="file"
            name="File Attachment"
            type="file"
            accept="*"
            style="display: none;"
            @change="handleFile"
            @click="clearAttachmentFile"
          />
          <span onclick="document.getElementById('fileAttachment').click()">
            <Icons type="la-paperclip" size="24" />
          </span>
          <span
            :class="{ 'q-text-primary': display.emoji }"
            @click="editComponentDisplay('emoji')"
          >
            <Icons type="la-smile" size="24" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
const Icons = () => import("../../Icons/index.vue");

export default {
  components: { VEmojiPicker, Icons },
  computed: {
    ...mapState("messenger", ["display", "message", "fileAttachments", "user"])
  },
  methods: {
    async sendMessage() {
      if (this.message.type === "text" && this.message.content) {
        this.$store
          .dispatch("conversation/sendMessage", {
            user: this.user,
            payload: {
              type: this.message.type,
              text: this.message.content,
              room_id: localStorage.getItem("qchat_room_id")
            }
          })
          .then(() => {})
          .catch(() => {});
        this.updateInputText({ target: { value: "" } });
        setTimeout(() => {
          this.scrollConversationToBottom();
        }, 200);
      }
    },
    onSelectEmoji(emoji) {
      this.message.content += emoji.data;
      this.editComponentDisplay("emoji");
    },
    clearAttachmentFile() {
      document.getElementById("fileAttachment").value = [];
    },
    handleFile(event) {
      const file = event.target.files[0];
      if (file) {
        const fileAtc = {
          id: `FILE_${file.name}_${this.fileAttachments.length}`,
          loading: 0,
          status: "pending",
          caption: "",
          type: this.getFileType(file.type),
          file
        };
        this.$store.commit("messenger/SET_IMAGE_TO_ATTACHMENT_LIST", fileAtc);
        this.startUpload(fileAtc);
      }
    },
    startUpload(file) {
      this.$store
        .dispatch("messenger/attachmentUpload", { file })
        .then(res => {
          this.$store.commit("messenger/UPDATE_ATTACHMENT_BY_INDEX", res);
        })
        .catch(err => {
          this.$store.commit("messenger/UPDATE_ATTACHMENT_BY_INDEX", err);
        });
    },
    editComponentDisplay(component) {
      this.$store.commit("messenger/UPDATE_DISPLAY", component);
    },
    updateInputText(e) {
      this.$store.commit("messenger/UPDATE_MESSAGE_INPUT", e.target.value);
    },
    getFileType(type) {
      if (type.includes("image")) return "image";
      else if (type.includes("audio")) return "audio";
      else if (type.includes("video")) return "video";
      else if (type.includes("application")) return "document";
      else return "";
    }
  }
};
</script>

<template>
  <div class="q-position-relative">
    <div id="conversations" class="qchat-conv">
      <div
        v-for="conv in formattedConversations"
        :key="conv.id"
        class="qslidein5s"
      >
        <div
          v-if="conv.displayDivider"
          class="qchat-conv__divider"
          :style="customStyles.divider"
        >
          {{ conv.dateCondition }}
        </div>
        <div
          class="qchat-conv__message"
          :class="conv.participant_type === 'customer' ? 'right' : 'left'"
        >
          <div
            class="qchat-conv__sender"
            :class="conv.participant_type === 'customer' ? 'right' : 'left'"
          >
            <div
              :id="conv.id"
              class="qchat-conv__bubble q-my-1 q-position-relative"
              :class="
                bubbleChatCondition(
                  conv.displayName,
                  conv.participant_type,
                  conv.sender.name,
                  conv.type
                )
              "
              :style="customStyles.bubbleChat"
            >
              <message-type
                :message="conv"
                :list-mode="conv.displayImageLists"
                :channel="'webchat'"
                :reply-msg="conv.reply"
              />
              <p
                class="qchat-conv__sender__text q-text-right"
                :style="{
                  color:
                    styles.conversation.bubbleChat[
                      conv.participant_type === 'customer' ? 'right' : 'left'
                    ].textColor
                }"
              >
                {{ convertToTime(conv.created_at) }}
                <Icons
                  v-if="conv.participant_type === 'customer'"
                  class="q-ml-1"
                  size="11"
                  :type="getStatusIcon(conv.status)"
                  :fill="getStatusColor(conv.status)"
                />
              </p>
              <div
                v-if="
                  conv.participant_type === 'customer' &&
                    conv.status === 'failed'
                "
                class="qchat-conv__bubble_resend q-pointer q-tooltip"
              >
                <span class="q-tooltiptext">Resend</span>
                <Icons type="la-redo-alt" size="10" @click="resendChat(conv)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <attachment-panel v-if="fileAttachments.length" />
  </div>
</template>
<script>
import { mapState } from "vuex";
const MessageType = () => import("../chat/MessageType.vue");
const AttachmentPanel = () => import("./AttachmentPanel.vue");
const Icons = () => import("../../Icons/index.vue");

export default {
  components: { MessageType, AttachmentPanel, Icons },
  computed: {
    ...mapState("messenger", ["user", "display", "fileAttachments", "styles"]),
    ...mapState("conversation", ["conversations"]),
    formattedConversations() {
      let conv = this.conversations.filter(data => data.type !== "system");
      for (const x in conv) {
        // Grouping By Day
        if (x < 1) {
          conv[x].displayDivider = true;
          conv[x].displayName = true;
        } else if (x > 0) {
          if (
            new Date(conv[x].created_at).getDay().toString() !==
            new Date(conv[x - 1].created_at).getDay().toString()
          )
            conv[x].displayDivider = true;
          else conv[x].displayDivider = false;

          // On same user in username display
          if (conv[x].displayDivider) {
            if (conv[x].sender_id === conv[x - 1].sender_id) {
              conv[x].displayName = true;
            } else {
              conv[x].displayName = true;
            }
          } else if (!conv[x].displayDivider) {
            if (conv[x].sender_id === conv[x - 1].sender_id) {
              conv[x].displayName = false;
            } else {
              conv[x].displayName = true;
            }
          }
        }

        // Check for grouping chat on same user

        if (x < conv.length - 1) {
          if (conv[x].sender_id === conv[parseInt(x) + 1].sender_id) {
            if (conv[parseInt(x) + 1].displayDivider)
              conv[x].displayTime = true;
            else conv[x].displayTime = false;
          } else {
            conv[x].displayTime = true;
          }
        } else {
          conv[x].displayTime = true;
        }

        // Create list for image with 4 or more pics
        conv[x].displayImageLists = "normal";
        if (conv[x].type === "image") {
          // If message type image
          if (x > 0) {
            if (
              conv[x].participant_type ===
              conv[parseInt(x) - 1].participant_type
            ) {
              // If participant type is same with previous message
              if (conv[parseInt(x) - 1].displayImageLists === "normal") {
                // If previous image list type is normal
                if (conv[parseInt(x) + 3] !== undefined) {
                  // If 4 messages after this is image
                  if (
                    conv[parseInt(x)].type === "image" &&
                    conv[parseInt(x) + 1].type === "image" &&
                    conv[parseInt(x) + 2].type === "image" &&
                    conv[parseInt(x) + 3].type === "image"
                  ) {
                    if (
                      new Date(
                        conv[parseInt(x) - 1].created_at
                      ).toLocaleString() +
                        ":" +
                        new Date(
                          conv[parseInt(x) - 1].created_at
                        ).getMilliseconds() ===
                      new Date(conv[x].created_at).toLocaleString() +
                        ":" +
                        new Date(conv[x].created_at).getMilliseconds()
                    ) {
                      conv[x].displayImageLists = "parent";
                    }
                  }
                }
              } else if (
                conv[parseInt(x) - 1].displayImageLists === "parent" ||
                conv[parseInt(x) - 1].displayImageLists === "child"
              ) {
                // If previous image list type is parent or child
                conv[x].displayImageLists = "child";
              }
            }
          }
        }

        // Grouping By Day (Detail)
        if (new Date(conv[x].created_at).getDay() === new Date().getDay())
          conv[x].dateCondition = "Today";
        else if (
          new Date(conv[x].created_at).getDay() ===
          new Date().getDay() - 1
        )
          conv[x].dateCondition = "Yesterday";
        else
          conv[x].dateCondition = new Date(conv[x].created_at).toLocaleString(
            "locale",
            {
              dateStyle: "full"
            }
          );
      }
      return conv;
    },
    customStyles() {
      return {
        divider: {
          "--divider-color": this.styles.conversation.divider.color,
          "--divider-line-color": this.styles.conversation.divider.lineColor
        },
        bubbleChat: {
          "--bubbleChat-left-mainColor": this.styles.conversation.bubbleChat
            .left.mainColor,
          "--bubbleChat-left-textColor": this.styles.conversation.bubbleChat
            .left.textColor,
          "--bubbleChat-left-shadow": this.styles.conversation.bubbleChat.left
            .shadow,
          "--bubbleChat-right-mainColor": this.styles.conversation.bubbleChat
            .right.mainColor,
          "--bubbleChat-right-textColor": this.styles.conversation.bubbleChat
            .right.textColor,
          "--bubbleChat-right-shadow": this.styles.conversation.bubbleChat.right
            .shadow
        }
      };
    }
  },
  mounted() {
    this.scrollConversationToBottom();
  },
  watch: {
    formattedConversations() {
      this.scrollConversationToBottom();
    }
  },
  methods: {
    convertToTime(date) {
      let time = new Date(date).toLocaleTimeString();
      time = time.substring(0, time.length - 6);
      return time;
    },
    bubbleChatCondition(displayed, type, name, messageType) {
      const isHighlighted = !this.isOwner(name) ? " highlighted" : "";
      if (type === "customer") {
        if (displayed)
          return "right tri" + isHighlighted + " " + messageType.toString();
        else return "right" + isHighlighted + " " + messageType.toString();
      } else if (type !== "customer") {
        if (displayed) return "left tri" + " " + messageType.toString();
        else return "left" + " " + messageType.toString();
      }
    },
    isOwner(name) {
      return this.user.name === name;
    },
    getStatusColor(status) {
      switch (status) {
        case "read":
          return "#0274f5";
        case "failed":
          return "#eb374d";
        default:
          return "currentColor";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "read":
          return "la-check-double";
        case "sent":
          return "la-check-double";
        case "delivered":
          return "la-check";
        case "pending":
          return "la-clock";
        case "failed":
          return "la-times";
        case "created":
          return "la-check";
      }
    },
    resendChat(conv) {
      this.$store
        .dispatch("conversation/sendMessage", {
          user: this.user,
          payload: {
            type: conv.type,
            text: conv.text,
            room_id: localStorage.getItem("qchat_room_id")
          }
        })
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-position-relative"},[_c('div',{staticClass:"qchat-conv",attrs:{"id":"conversations"}},_vm._l((_vm.formattedConversations),function(conv){return _c('div',{key:conv.id,staticClass:"qslidein5s"},[(conv.displayDivider)?_c('div',{staticClass:"qchat-conv__divider",style:(_vm.customStyles.divider)},[_vm._v(" "+_vm._s(conv.dateCondition)+" ")]):_vm._e(),_c('div',{staticClass:"qchat-conv__message",class:conv.participant_type === 'customer' ? 'right' : 'left'},[_c('div',{staticClass:"qchat-conv__sender",class:conv.participant_type === 'customer' ? 'right' : 'left'},[_c('div',{staticClass:"qchat-conv__bubble q-my-1 q-position-relative",class:_vm.bubbleChatCondition(
                conv.displayName,
                conv.participant_type,
                conv.sender.name,
                conv.type
              ),style:(_vm.customStyles.bubbleChat),attrs:{"id":conv.id}},[_c('message-type',{attrs:{"message":conv,"list-mode":conv.displayImageLists,"channel":'webchat',"reply-msg":conv.reply}}),_c('p',{staticClass:"qchat-conv__sender__text q-text-right",style:({
                color:
                  _vm.styles.conversation.bubbleChat[
                    conv.participant_type === 'customer' ? 'right' : 'left'
                  ].textColor
              })},[_vm._v(" "+_vm._s(_vm.convertToTime(conv.created_at))+" "),(conv.participant_type === 'customer')?_c('Icons',{staticClass:"q-ml-1",attrs:{"size":"11","type":_vm.getStatusIcon(conv.status),"fill":_vm.getStatusColor(conv.status)}}):_vm._e()],1),(
                conv.participant_type === 'customer' &&
                  conv.status === 'failed'
              )?_c('div',{staticClass:"qchat-conv__bubble_resend q-pointer q-tooltip"},[_c('span',{staticClass:"q-tooltiptext"},[_vm._v("Resend")]),_c('Icons',{attrs:{"type":"la-redo-alt","size":"10"},on:{"click":function($event){return _vm.resendChat(conv)}}})],1):_vm._e()],1)])])])}),0),(_vm.fileAttachments.length)?_c('attachment-panel'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }